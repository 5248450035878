import React from "react";
import { Link } from "react-router-dom";
import Bill from "../Bill/Bill";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";

import "./Bills.css";

import Intro from "../Intro/Intro";

export default function Bills() {
  return (
    <>
      <Header />
      <div className="container mt-5">
        <Link to="/">
          <div className="cont container" id="cont-res">
            {" "}
            <button
              type="submit"
              className="btn form-control btn-primary  mt-2 mb-1"
              style={{
                backgroundImage:
                  "linear-gradient(to bottom right, hsl(187,85%,43%), rgb(40, 46, 229) 100%)",
                fontSize: "12px",
              }}
            >
              Purchase more products
            </button>
          </div>
        </Link>

        <Bill />
      </div>

      <div className="container oth" id="oth-res">
        {" "}
        <p>Other Products</p>
      </div>

      <Intro id="8" />
      <Footer />
    </>
  );
}
