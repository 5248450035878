import React, { useEffect, useState } from "react";
import Galry from "../Galry/Galry";

import Header from "../Header/Header";
import "./Form.css";
import Footer from "../Footer/Footer";
import { Link, useParams } from "react-router-dom";
import http from "../../../http";
import Forms from "./Forms";

import Intro from "../Intro/Intro";
export default function P() {
  const [data, setData] = useState([]);
  const { id } = useParams();
  useEffect(() => {
    const fetchAllData = () => {
      http.get("/showByIdProduct/" + id).then((res) => {
        setData(res.data);
     
      });
    };
    fetchAllData();
  }, [id]);
  return (
    <>
      <Header />
      <div className="containers mt-5" >
      <div className="row justify-content-center ">
      <div className="col-12 col-lg-6 mt-3 col-md-6 col-sm-12 " > <Galry /></div>
      <div className="col-12 col-lg-6 mt-3 col-md-6 col-sm-12 " ><Forms ids={id} /></div>

      </div>
    
      </div>
      <div className="containers ">
      <div class="card" style={{margin:".5rem"}}>
  <div class="card-header">
  Description
  </div>
  <div class="card-body" style={{marginBottom:"1rem"}}>
  
    <p class="card-text">{data.Des}</p>
    
  </div>
</div>
      </div>

      <div className="container other" id="other-resp">
        {" "}
        <p style={{fontSize:"20px"}}>Other Products</p>
      </div>
      <div >
        <Intro id="8" />
      </div>

      <Footer id="foot-res" />
    </>
  );
}
