import React, { useEffect, useState } from "react";
import { ImBin } from "react-icons/im";
import { Link } from "react-router-dom";
import http from "../../../http";
import Intro from "../Intro/Intro";

export default function Bill() {
  const key = JSON.parse(localStorage.getItem("deal-info"));
  const [datas, setData] = useState([]);
  var totalbill = 0;
  var itm = 0;
  useEffect(() => {
    fetchAllData();
  }, []);
  const fetchAllData = () => {
    http.get("/showByIdOrder/" + key.id).then((res) => {
      setData(res.data);
    });
  };
  const deleteData = (id) => {
    http.delete("/orderDelete/" + id).then((res) => {
      fetchAllData();
    });
  };

  return (
    <>
      <div
        className="container-fluid"
       
        style={{ justifyContent: "center" , margin:".5px"}}
      >
        <div
          className="container-fluid shad mt-3 opts "
          style={{ background: "white", height: "auto" }}
        >
          <div className="form-group ">
        <br></br>
              <div class="alert alert-info opt "   
                id="opt-res" role="alert">
  Your order Confirmed!
</div>
              <br />
              <label
                htmlFor="exampleInputEmail1"
                className="opt"
                id="opt-res"
              >
                Hello, {key.name}
              </label>
              <br />
              <label
                htmlFor="exampleInputEmail1"
                className="opt mt-2"
                id="opt-res"
              >
                You order has been confirmed and will be shipped in next two
                working days!
              </label>
              <br />
            </div>
            <div >
            <label
                htmlFor="exampleInputEmail1"
                className="opt mt-2"
                id="opt-res"
              >
          Order No <br></br> {key.id}
              </label>
              <label
                htmlFor="exampleInputEmail1"
                className="opt mt-2 ml"
                id="opt-res"
              >
                 Shiping Address: <br></br>
                      
                      {key.emi},
                      {key.address}
                   
              </label>
              <label
                htmlFor="exampleInputEmail1"
                className="opt mt-2 ml"
                id="opt-res"
              >
                 Payment 
                 <br></br>
                 Cash on Delivery
              </label>
        
            </div>
            {datas.map((item, index) => {
                    itm = itm + 1;
                    totalbill += item.qun * item.afterOff;
                    return ( 
                      <div >
            <label
                htmlFor="exampleInputEmail1"
                className="opt mt-2"
                id="opt-res"
              >
           <img
                            src={
                              "https://api.dealsmart.ae/Products/" + item.img
                            }
                            alt=""
                           style={{width:"4rem"}}
                          ></img>
              </label>
              <label
                htmlFor="exampleInputEmail1"
                className="opt mt-2 ml"
                id="opt-res"
              >
                 Quantity: {item.qun}
                      <br></br>
                      {item.smallDesc}
                
                   
              </label>
              <label
                htmlFor="exampleInputEmail1"
                className="opt mt-2 ml"
                id="opt-res"
              >
                 {item.qun * item.afterOff} AED
                 <Link to="/Bills">
                            <ImBin
                              onClick={() => deleteData(item.id)}
                              style={{ marginLeft: "10px" }}
                            />
                          </Link>
              </label>
            </div> 
                    );
                  })}
                     <div style={{textAlign:"center", alignItems:"centar"}}>
            <label
                htmlFor="exampleInputEmail1"
                className="opt mt-2"
                id="opt-res"
              >
    Subtotal:
              </label>
              <label
                htmlFor="exampleInputEmail1"
                className="opt mt-2 ml"
                id="opt-res"
              >
                {totalbill} AED{" "}    
                   
              </label>
            </div> 
            <div style={{textAlign:"center", alignItems:"centar"}}>
            <label
                htmlFor="exampleInputEmail1"
                className="opt mt-2"
                id="opt-res"
              >
     Shipping Fee: 
              </label>
              <label
                htmlFor="exampleInputEmail1"
                className="opt mt-2 ml"
                id="opt-res"
              >
                  Free
                   
              </label>
            </div> 
            <div style={{textAlign:"center", alignItems:"centar"}}>
            <label
                htmlFor="exampleInputEmail1"
                className="opt mt-2"
                id="opt-res"
              >
   Grand Total: 
              </label>
              <label
                htmlFor="exampleInputEmail1"
                className="opt mt-2 ml"
                id="opt-res"
              >
                 {totalbill} AED
                 {/* {itm} AED */}
                 {localStorage.setItem("item-info", JSON.stringify(itm))}
              </label>
            </div> 
            <div style={{textAlign:"center", alignItems:"centar"}}>
            <label
                htmlFor="exampleInputEmail1"
                className="opt mt-2"
                id="opt-res"
              >
Thanks for shopping with us!
              </label>
              
            </div> 
            <div style={{textAlign:"center", alignItems:"centar"}}>
            <label
                htmlFor="exampleInputEmail1"
                className="opt mt-2"
                id="opt-res"
              >
 <Link to="/ClearBill">
                        <button type="submit" className="button mb-5">
                        New Order
                        </button>
                      </Link>
              </label>
              
            </div> 
        </div>
      </div>
      <div className="container">
        <Intro />
      </div>
    </>
  );
}
